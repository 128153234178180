.intro {
    width: 50%;
    padding-left: 10%;
    padding-top: 100px;
    padding-bottom: 100px;
    float: left;
}

.apresentacao {
    padding-bottom: 20px;
    padding-left: 20px;
}

.banner {
    width: 100%;
    background-color: #2e3192;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-container {
    color: white;
    text-align: center;
    font-size: 40px;
}

.intro-container {
    width: 90%;
    position: relative;
    padding-left: 5%;
    padding-bottom: 100px;
}

.intro-container:after {
    content: "";
    clear: both;
    display: table;
}

.sede {
    float: right;
    padding-top: 7%;
    padding-right: 10%;
    width: 50%;
}

.caapi {
    padding-left: 8%;
    padding-right: 8%;
    float: left;
    width: 40%;
}

.constr-img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.objetivos-container {
    float: right;
    width: 60%;
}

.objetivos {
    padding-bottom: 20px;
    padding-left: 20px;
}

.icon-obj {
    color: #f15a24;
    width: 20px;
    float: left;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
    .sede {
        width: 100%;
    }

    .intro {
        width: 100%;
        padding-bottom: 0;
    }

    .sede {
        padding-right: 0;
    }

    .caapi {
        width: 100%;
    }

    .objetivos-container {
        width: 100%;
        padding-top: 30px;
    }


}

