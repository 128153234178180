.site-header {
    position: relative;
}

.Navbar {
    background-color: #ffffff;
}

.nav-container {
    float: right;
    padding-right: 5%;
    padding-top: 20px;
}

.nav-element {
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 20px;
    color: gray;
    height: 50px;
}

.nav-element:hover{
    border-bottom: 4px solid #f15a24;
    color: #ff871f !important;
    text-decoration: none;
}

.nav-link.active {
    border-bottom: 4px solid #ff871f;
    color: #ff871f !important;
}

.logo-container {
    width: 30%;
}

.image-size {
    float: left;
    max-width: 70%;
}

.site-header:after {
    content: "";
    clear: both;
    display: table;
  }

@media screen and (max-width: 900px) and (min-width: 300px) {
    .logo-container {
        width: 50%;
    }

    .nav-container {
        width: 50%;
    }

    .Navbar {
        float: right;
    }

    .image-size {
        max-width: 100%;
    }

}