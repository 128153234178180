.image-desc-container {
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.desc {
    color: blue;
}

.image-desc-container:after {
    content: "";
    clear: both;
    display: table;
}