.site-footer {
    background-color: #f15a24;
    color: #ffffff;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 320px;
}

.site-footer:after {
    content: "";
    clear: both;
    display: table;
}

.container {
    width: 75%;
}

.footer-col {
    width: 33%;
    height: 170px;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
    border-right: 1px solid white;
}

.dados-contato:after {
    content: "";
    clear: both;
    display: table;
}

.last-col {
    border-right: none;
}

.logo {
    max-width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
}

.dados-contato {
    padding-left: 5%;
    padding-top: 10px;
}

.icon-container {
    color: #ffffff;
}

.socials-container {
    padding-left: 15%;
    padding-top: 60px;
}

.social-icon {
    color: #ffffff;
    padding-left: 10%;
}

.social-icon:hover {
    color: #ffffff;
}

.cel-number {
    padding-left: 20px;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
    .footer-col {
        width: 100%;
        height: auto;
        border-bottom: 1px solid white;
        border-right: none;
    }

    .last-col {
        border-bottom: none;
    }

    .logo {
        max-width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }

    .dados-contato {
        width: 90%;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0;
    }


}