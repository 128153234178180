.equipe {
    width: 80%;
    margin-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.equipe-cargo {
    padding-bottom: 20px;
}

.cargo-titulo {
    font-size: 32px;
}

.equipe-membro {
    margin-left: 40px;
}

.equipe-membro:after {
    content: "";
    clear: both;
    display: table;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
    .equipe {
        width: 100%;
        margin-left: 5%;
    }

    .equipe-membro {
        margin-left: 20px;
    }


}