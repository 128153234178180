.atuacao-banner {
    width: 100%;
    background-color: #2e3192;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 40px;
}

.atuacao-container {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 50px;
}

.atuacao {
    background-color: #99a2e0;
    margin-top: 30px;
    color: white;
    padding-bottom: 20px;
    padding-top: 10px;
}

.atuacao-conteudo {
    margin-left: 10%;
}

.atuacao-title {
    padding-left: 15px;
}

.atuacao-title-word {
    font-size: 24px;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
    .atuacao-container {
        width: 90%;
        margin-left: 5%;
    }


}