.construcoes-container {
    width: 80%;
    padding-top: 100px;
    padding-left: 10%;
    background-color: white;
    margin: 0 auto;
    padding-bottom: 50px;
}

.construcao {
    position: relative;
    background-color: #2e3192;
    color: white;
    width: 25%;
    font-size: 13px;
    line-height: normal;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 2px;
    padding-right: 5px;
    margin-right: 5%;
    height: 200px;
    float: left;
}

.construcao-container:after {
    content: "";
    clear: both;
    display: table;
}

.projetos-banner {
    width: 100%;
    background-color: #f15a24;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 40px;
}

.imagens-construcao-container {
    width: 80%;
    margin-left: 10%;
    padding-top: 50px;
    padding-bottom: 80px;
    text-align: center;
}

.imagens-construcao-container:after {
    content: "";
    clear: both;
    display: table;
}

.imagens-construcao {
    padding-top: 50px;
}

.imagem-construcao-container {
    float: left;
    width: 50%;
}

.imagem-construcao {
    max-width: 100%;
    padding-bottom: 30px;
}

.title-banner {
    width: 100%;
    background-color: #2e3192;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 40px;
}

.consultoria {
    position: relative;
    background-color: #2e3192;
    color: white;
    width: 80%;
    font-size: 13px;
    line-height: normal;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 2px;
    padding-right: 5px;
    margin-right: 5%;
    height: 200px;
    float: left;
}

.reforma {
    background-color: #f15a24;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
    .imagens-construcao-container {
        width: 100%;
        margin-left: 0;
    }

    .imagem-construcao-container {
        width: 100%;
    }

    .construcao {
        width: 100%;
    }

    .consultoria {
        width: 100%;
    }

    .construcoes-container {
        padding-left: 0;
    }

    .imagem-construcao {
        padding-top: 30px;
    }
}