.App {
    background-color: #282c34;
    background-image: url(bg-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left;
  }

  